/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import {
  DownOutlined,
  HomeOutlined,
  LeftSquareOutlined,
  LoadingOutlined,
  PlusOutlined,
  RightSquareOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Avatar,
  Button,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
  Upload,
} from "antd";
import "antd/dist/antd.css";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { DebounceInput } from "react-debounce-input";
import { Link, useHistory } from "react-router-dom";
import "../../App.min.css";
import useDebounce from "../../hooks/useDebounce";
import {
  getBase64,
  getFileExtension,
  validImageExtensions,
} from "../../Utils/file";
import Loader from "../../Utils/Loader";
import {
  getAllUser,
  getCategories,
  manualTransfer,
  updateUserDetails,
  uploadImage,
} from "../api/index";

const AllUser = ({ sensitiveAllowedActions }) => {
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [user, setUser] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [notFoundContent, setNotFoundContent] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [filterUserType, setFilterUserType] = useState("creator_completed");
  const [categories, setCategories] = useState([]);
  // const[totalUserCount, setUserTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [selectedUserForEdit, setSelectedUserForEdit] = useState(null);
  const [selectedUserForMGPay, setSelectedUserForMGPay] = useState(null);
  const [reloadPage, setReloadPage] = useState(false);
  const [file, setFile] = useState(null);
  const [imgLoading, setImgLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const debouncedSearchQuery = useDebounce({
    value: searchQuery,
    callback: () => setPage(1),
  });
  const [tableKey, setTableKey] = useState(Date.now());

  const errorHandle = (error) => {
    if (error.status === 401 || error.code === 401) {
      localStorage.clear();
      history.push("/");
      error?.message || error?.result
        ? message.error(error?.message || error?.result)
        : message.error("Something went wrong");
    } else {
      error?.message || error?.result
        ? message.error(error?.message || error?.result)
        : message.error("Something went wrong");
    }
  };
  const getCreatorSearch = (query) => {
    setReloadPage(false);
    setLoader(true);
    getAllUser(page, query, filterUserType)
      .then((response) => {
        setLoader(false);
        if (response.code === 0) {
          // if (response?.result?.users?.length) {
          setUser([...response?.result?.users]);
          const reducedData = [...response?.result?.users].reduce(
            (acc, eachDetails, index) => {
              return [
                ...acc,
                {
                  key: eachDetails?._id,
                  index,
                  name: eachDetails?.name,
                  profilePicUrl: eachDetails?.profilePicUrl,
                  gender: eachDetails?.gender,
                  mangoes: eachDetails?.mangoes,
                  email: eachDetails?.email,
                  phone: eachDetails?.phone,
                  rank: eachDetails?.rank,
                  otp: eachDetails?.otp,
                  onboarding: onboardingObj[eachDetails?.onboarding],
                  accountName: eachDetails?.accountName,
                  accountNumber: eachDetails?.accountNumber,
                  bankName: eachDetails?.bankName,
                  ifsc: eachDetails?.ifsc,
                  mgAmount: eachDetails?.mgAmount,
                  tmCommission: eachDetails?.tmCommission,
                  category: eachDetails?.category?.name || "N/A",
                  subCategory: eachDetails?.subCategory?.name || "N/A",
                  activeCount: eachDetails?.activeCount,
                  initiatedCount: eachDetails?.initiatedCount,
                  activeSubscribedMangoes: eachDetails?.activeSubscribedMangoes,
                  activeCategories: eachDetails?.activeCategories,
                  activeCreators: eachDetails?.activeCreators,
                  activeSubscribedMangoesStr:
                    eachDetails?.activeSubscribedMangoes
                      ?.map((m) => m.title)
                      ?.join(", "),
                  activeCategoriesStr: eachDetails?.activeCategories
                    ?.map((m) => m.name)
                    ?.join(", "),
                  activeCreatorsStr: eachDetails?.activeCreators
                    ?.map((m) => m.name)
                    ?.join(", "),
                  convenienceFee: eachDetails?.convenienceFee,
                  whitelableEnabled: !!eachDetails?.whitelableEnabled,
                },
              ];
            },
            []
          );
          setTableData(reducedData);
          // setUserTotalCount(response?.result?.userCount);
          setTotalPage(Math.ceil(response?.result?.userCount / 100));
          setTableKey(Date.now());
        }
        // }
      })
      .catch((error) => {
        errorHandle(error);
      });
  };

  const getCategoriesData = async () => {
    try {
      const resp = await getCategories();
      if (resp.code === 0) {
        setCategories(resp.result);
      } else {
        message.error(resp.message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  user.map((data) => {
    data.allMangoes = data?.mangoes
      ?.map((mango) => mango.title)
      .filter((name) => name !== "Public");
  });

  useEffect(() => {
    getCreatorSearch(debouncedSearchQuery, filterUserType);
  }, [debouncedSearchQuery, page, filterUserType, reloadPage]);

  useEffect(() => {
    getCategoriesData();
  }, []);

  // const onSearch = (query) => {
  //   setNotFoundContent(false);

  //   setSearchQuery(query);
  //   if (query.length < 2) {
  //     setUser([]);
  //     setPage(1);
  //   } else if (query.length === 0) {
  //     setUser([]);
  //     setPage(1);
  //   }
  // };

  const handleCLick = (action) => {
    if (action === "home") {
      setPage(1);
    }

    if (action === "next") {
      setPage(page + 1);
    }

    if (action === "previous") {
      setPage(page - 1);
    }
  };

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: "Mango Title",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
      },
      // {
      //   title: "recurringType",
      //   dataIndex: "recurringType",
      //   key: "recurringType",
      // },
      // {
      //   title: "Video call",
      //   dataIndex: "videocall",
      //   key: "videocall",
      //   render: (text, record) => (
      //     <Checkbox style={{ cursor: "none" }} checked={record?.videocall} />
      //   ),
      // },
      // {
      //   title: "Content",
      //   dataIndex: "content",
      //   key: "content",
      //   render: (text, record) => (
      //     <Checkbox style={{ cursor: "none" }} checked={record?.content} />
      //   ),
      // },
      // {
      //   title: "Chat",
      //   dataIndex: "chat",
      //   key: "chat",
      //   render: (text, record) => (
      //     <Checkbox style={{ cursor: "none" }} checked={record?.chat} />
      //   ),
      // },
      // {
      //   title: "WhatsApp",
      //   dataIndex: "whatsapp",
      //   key: "whatsapp",
      //   render: (text, record) => (
      //     <Checkbox
      //       style={{ cursor: "none" }}
      //       checked={record?.whatsapp === "approved"}
      //     />
      //   ),
      // },
    ];

    const subscribedColumns = [
      {
        title: "Mango Title",
        dataIndex: "title",
        key: "title",
      },
    ];

    const recurringTypeObj = {
      onetime: "Onetime",
      monthly: "Monthly",
    };

    const data = record.mangoes.map((eachMango) => {
      return {
        title: eachMango?.title,
        price: eachMango?.price,
        recurringType: recurringTypeObj[eachMango?.recurringType],
        videocall: eachMango?.videocall,
        chat: eachMango?.chat,
        content: eachMango?.content,
        whatsapp: eachMango?.whatsapp,
      };
    });

    const subscribed = record?.activeSubscribedMangoes?.map((eachMango) => {
      return {
        title: eachMango?.title,
      };
    });

    return (
      <Row>
        {data && data?.length > 0 && (
          <Col span={24}>
            <Typography>Created Mangoes</Typography>
            <Table
              columns={columns}
              dataSource={data}
              pagination={false}
              bordered
            />
          </Col>
        )}
        {subscribed && subscribed?.length > 0 && (
          <Col span={24}>
            <Typography>Subscribed Mangoes</Typography>
            <Table
              columns={subscribedColumns}
              dataSource={subscribed}
              pagination={false}
              bordered
            />
          </Col>
        )}
      </Row>
    );
  };

  const pageDisplay = () => {
    return (
      <p style={{ display: "contents" }}>
        Page {page} of {totalPage}
      </p>
    );
  };

  const onFinishMGPayModal = async ({ manualTranserID, transerAmount }) => {
    let transferAmountFloat = 0;
    if (!file) {
      message.error("Pay slip is required");
      return;
    }
    try {
      transferAmountFloat = parseFloat(transerAmount);
      const formData = new FormData();
      formData.append("image", file);
      setButtonLoading(true);
      uploadImage(formData)
        .then((res) => {
          if (res.code === 0) {
            const fileUrl =
              res?.result?.compressed ||
              (res?.result && res?.result?.files && res?.result?.files[0]);
            manualTransfer({
              orders: [],
              paySlip: fileUrl,
              totalTransferredAmount: transferAmountFloat,
              manualTransferId: manualTranserID,
              creatorId: selectedUserForMGPay.key,
              payMG: true,
            })
              .then(() => {
                message.success("MG  completed successfully !!");
                setButtonLoading(false);
                setFileAndThumbnail(null, null);
                setSelectedUserForMGPay(null);
              })
              .catch((error) => {
                setButtonLoading(false);
                setFileAndThumbnail(null, null);
                setSelectedUserForMGPay(null);
                console.log(error);
                error?.message
                  ? message.error(error?.message || error?.result)
                  : message.error("Something went wrong");
              });
          }
        })
        .catch((error) => {
          setButtonLoading(false);
          setFileAndThumbnail(null, null);
          setSelectedUserForMGPay(null);
          console.log(error);
          error?.message
            ? message.error(error?.message || error?.result)
            : message.error("Something went wrong");
        });
    } catch (error) {
      console.log(error);
      errorHandle(error);
    }
  };

  const onFinishEditModal = async (value) => {
    const {
      tmCommission,
      convenienceFee,
      razorpayOnetimeComission,
      razorpayRecurringComission,
      stripeOnetimeComission,
      stripeRecurringComission,
    } = value;

    let applyCommissionCheck = false;

    const withRazorpayOnetimeComission =
      tmCommission + convenienceFee + razorpayOnetimeComission;
    const withRazorpayRecurringComission =
      tmCommission + convenienceFee + razorpayRecurringComission;
    const withStripeOnetimeComission =
      tmCommission + convenienceFee + stripeOnetimeComission;
    const withStripeRecurringComission =
      tmCommission + convenienceFee + stripeRecurringComission;

    const tier1Andtier2Threshold = 2;
    const tier3Andtier4Threshold = 0.9;

    if (value?.userTier) {
      if (value.userTier === "tier1" || value.userTier === "tier2") {
        applyCommissionCheck =
          withRazorpayOnetimeComission < tier1Andtier2Threshold ||
          withRazorpayRecurringComission < tier1Andtier2Threshold ||
          withStripeOnetimeComission < tier1Andtier2Threshold ||
          withStripeRecurringComission < tier1Andtier2Threshold;
      } else if (value.userTier === "tier3" || value.userTier === "tier4") {
        applyCommissionCheck =
          withRazorpayOnetimeComission < tier3Andtier4Threshold ||
          withRazorpayRecurringComission < tier3Andtier4Threshold ||
          withStripeOnetimeComission < tier3Andtier4Threshold ||
          withStripeRecurringComission < tier3Andtier4Threshold;
      }

      if (applyCommissionCheck) {
        message.error(
          "Commission should be greater than 2% for tier1 and tier2 and 0.9% for tier3 and tier4"
        );
        return;
      }
    }

    try {
      const updatedUser = await updateUserDetails({
        ...value,
        creatorId: selectedUserForEdit?._id,
      });
      if (updatedUser.code === 0) {
        setSelectedUserForEdit(null);
        // setIsModalVisible(false);
        setReloadPage(true);
      } else {
        errorHandle(updatedUser);
      }
    } catch (error) {
      errorHandle(error);
    }
  };

  const selectUserFilter = () => {
    const handleMenuClick = (e) => {
      setFilterUserType(e?.key);
    };

    const menu = (
      <Menu onClick={handleMenuClick} selectedKeys={[filterUserType]}>
        {/* <Menu.Item key="all" icon={<UserOutlined />}>
          All User
        </Menu.Item> */}
        <Menu.Item key="creator_completed" icon={<UserOutlined />}>
          Creator
        </Menu.Item>
        <Menu.Item key="fan_completed" icon={<UserOutlined />}>
          Subscriber
        </Menu.Item>
        {/* <Menu.Item key="dual" icon={<UserOutlined />}>
          Dual
        </Menu.Item> */}
      </Menu>
    );

    return (
      <Dropdown overlay={menu}>
        <Button>
          User Type <DownOutlined />
        </Button>
      </Dropdown>
    );
  };

  const setFileAndThumbnail = (imageFile, url) => {
    setImgLoading(false);
    setFile(imageFile);
    setImageUrl(url);
  };

  const beforeUpload = (inputfile) => {
    const isValidExtension =
      validImageExtensions.indexOf(inputfile.type) !== -1;

    if (!isValidExtension) {
      alert("You can only valid image type!");
      return;
    }

    const isValidSize = inputfile.size / 1024 / 1024 < 1024;
    if (!isValidSize) {
      alert("Image must be smaller than 1024MB!");
      return;
    }

    const type = getFileExtension(inputfile.name)[1].toLowerCase();

    setImgLoading(true);
    setImageUrl(null);
    if (isValidExtension && isValidSize) {
      // convertImage(type, inputfile, setFileAndThumbnail);
      getBase64(inputfile, (url) => {
        setFileAndThumbnail(inputfile, url);
      });
    } else {
      setFile(null);
    }
    return false;
  };

  const handleEditClick = (userId, index) => {
    setSelectedUserForEdit(user[index]);
  };

  const handlePayMGClick = (userDetails) => {
    setSelectedUserForMGPay(userDetails);
  };

  const handleCancel = () => {
    setSelectedUserForEdit(null);
    setSelectedUserForMGPay(null);
  };

  const onboardingObj = {
    dual: "Dual",
    fan_completed: "Fan",
    creator_completed: "Creator",
  };

  const sensitiveAllowedFunctionSet = useMemo(
    () => new Set(sensitiveAllowedActions),
    [sensitiveAllowedActions]
  );

  const displayUserDetails = () => {
    const columnData = [
      {
        title: "Creator Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) =>
          record?.onboarding === "Creator" || record?.onboarding === "Dual" ? (
            <a
              href={`${process.env.REACT_APP_TM_PLATFORM_BASE_URL}/creator/profile/${record?.key}`}
              target="_blank"
              rel="noreferrer"
            >
              <Space size="small">
                <Avatar src={record.profilePicUrl} size={32} />
                <Typography
                  style={{ fontWeight: 500, fontSize: 14, color: "#1890ff" }}
                >
                  {text}
                </Typography>
              </Space>
            </a>
          ) : (
            <Link to={`/dashboard?userId=${record?.key}`}>
              <Space size="small">
                <Avatar src={record.profilePicUrl} size={32} />
                <Typography
                  style={{ fontWeight: 500, fontSize: 14, color: "#1890ff" }}
                >
                  {text}
                </Typography>
              </Space>
            </Link>
          ),
      },
      {
        title: "Gender",
        dataIndex: "gender",
        key: "gender",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "No of Mangoes",
        dataIndex: "noOfMangoes",
        render: (text, record) => (
          <>
            <Typography style={{ fontWeight: 500, fontSize: 14 }}>
              {record?.mangoes?.length || 0}
            </Typography>
          </>
        ),
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
      },
      // {
      //   title: "OTP",
      //   dataIndex: "otp",
      //   key: "otp",
      //   render: (text, record) => (
      //     <Typography style={{ fontSize: 14 }}>
      //       {record?.otp || "null"}
      //     </Typography>
      //   ),
      // },
      {
        title: "Rank",
        dataIndex: "rank",
        key: "rank",
      },
      {
        title: "Comission",
        dataIndex: "tmCommission",
        key: "tmCommission",
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        render: (text, record) => (
          <Typography style={{ fontSize: 14 }}>
            {" "}
            {record?.category} &gt; {record?.subCategory}{" "}
          </Typography>
        ),
      },
      {
        title: "Status",
        dataIndex: "onboarding",
        key: "onboarding",
      },
      {
        title: "White Label Enabled Status",
        dataIndex: "whitelableEnabled",
        key: "whitelableEnabled",
        render: (text, record) => (
          <Tag
            bordered={false}
            color={record?.whitelableEnabled ? "success" : "warning"}
          >
            {record?.whitelableEnabled ? "enabled" : "disabled"}
          </Tag>
        ),
      },
      {
        title: "Subscribed To (as subscriber)",
        dataIndex: "activeCount",
        key: "activeCount",
        render: (text, record) => (
          <Typography style={{ fontSize: 14 }}>
            {record?.activeCount || 0} Active <br />
            {record?.initiatedCount || 0} Initiated
          </Typography>
        ),
      },
      {
        title: "Subscribed to Categories",
        dataIndex: "activeCategoriesStr",
        key: "activeCategoriesStr",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (text, record) => (
          <>
            <Button
              type="primary"
              disabled={record?.onboarding === "Fan"}
              onClick={() => handleEditClick(record?.key, record?.index)}
            >
              Edit
            </Button>{" "}
            {/* <Button
              type="primary"
              disabled={record?.onboarding === "Fan"}
              onClick={() =>
                handlePayMGClick({
                  key: record?.key,
                  email: record?.email,
                  gender: record?.gender,
                  name: record?.name,
                  onboarding: record?.onboarding,
                  phone: record?.phone,
                  profilePicUrl: record?.profilePicUrl,
                  accountName: record?.accountName,
                  accountNumber: record?.accountNumber,
                  bankName: record?.bankName,
                  ifsc: record?.ifsc,
                })
              }
            >
              Pay MG
            </Button> */}
          </>
        ),
      },
    ];
    return (
      <>
        <Table
          key={tableKey}
          columns={columnData}
          dataSource={tableData}
          expandable={{ expandedRowRender }}
          pagination={{ pageSize: 100, hideOnSinglePage: true }}
          scroll={{
            y: 1000,
            x: 1300,
          }}
        />
      </>
    );
  };

  // const headers = [
  //   { label: "Name", key: "name" },
  //   { label: "Email", key: "email" },
  //   { label: "Gender", key: "gender" },
  //   { label: "Profile Pic", key: "profilePicUrl" },
  //   { label: "Discovery Rank", key: "rank" },
  //   { label: "Phone No.", key: "phone" },
  //   { label: "Onboarding status", key: "onboarding" },
  //   { label: "Mangoes", key: "allMangoes" },
  // ];

  return (
    <>
      <Input
        minLength={2}
        className="searchbar"
        value={searchQuery}
        style={{ width: "100%" }}
        placeholder="Search a creator name, email, phone"
        onChange={(event) => setSearchQuery(event.target.value)}
      />
      {selectUserFilter()}
      <CSVLink
        filename={`all_users_${new Date()}.csv`}
        data={tableData}
        // headers={headers}
      >
        <Button>Export CSV</Button>
      </CSVLink>
      {loader ? (
        <Loader />
      ) : (
        <>
          {displayUserDetails()}
          {pageDisplay()}
          <div style={{ float: "right" }}>
            <Button
              type="primary"
              disabled={page === 1}
              icon={<LeftSquareOutlined />}
              onClick={() => handleCLick("previous")}
            />
            <Button
              type="primary"
              disabled={page === 1}
              icon={<HomeOutlined />}
              onClick={() => handleCLick("home")}
            />
            <Button
              type="primary"
              disabled={page === totalPage}
              icon={<RightSquareOutlined />}
              onClick={() => handleCLick("next")}
            />
          </div>
        </>
      )}
      {/* Modal for chnage the rank of the user */}
      <Modal
        title="Edit User Profile"
        visible={selectedUserForEdit}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        destroyOnClose
      >
        <Space size="small">
          <Avatar src={selectedUserForEdit?.profilePicUrl} size={32} />
          <Typography style={{ fontWeight: 500, fontSize: 14 }}>
            {selectedUserForEdit?.name}(
            {onboardingObj[selectedUserForEdit?.onboarding]})
          </Typography>
        </Space>
        <Form
          name="basic"
          initialValues={{
            rank: selectedUserForEdit?.rank,
            tmCommission: selectedUserForEdit?.tmCommission || 0,
            convenienceFee: selectedUserForEdit?.convenienceFee || 0,
            razorpayOnetimeComission:
              selectedUserForEdit?.razorpayOnetimeComission || 0,
            razorpayRecurringComission:
              selectedUserForEdit?.razorpayRecurringComission || 0,
            stripeOnetimeComission:
              selectedUserForEdit?.stripeOnetimeComission || 0,
            stripeRecurringComission:
              selectedUserForEdit?.stripeRecurringComission || 0,
            category: selectedUserForEdit?.category?._id,
            subCategory: selectedUserForEdit?.subCategory?._id,
            whitelableEnabled: selectedUserForEdit?.whitelableEnabled,

            userTier: selectedUserForEdit?.userTier,
          }}
          onFinish={onFinishEditModal}
        >
          <Form.Item
            label="Rank"
            name="rank"
            rules={[{ required: true, message: "Please input rank!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="User Tier" name="userTier">
            <Input disabled />
          </Form.Item>
          {sensitiveAllowedFunctionSet.has("updateCommissionOrPg") && (
            <>
              <Form.Item label="Commission %" name="tmCommission">
                <InputNumber />
              </Form.Item>
              <>
                <Form.Item label="Convenience %" name="convenienceFee">
                  <InputNumber />
                </Form.Item>
                <Form.Item
                  label="Razorpay One Time Commission %"
                  name="razorpayOnetimeComission"
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item
                  label="Razorpay Recurring Commission %"
                  name="razorpayRecurringComission"
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item
                  label="Stripe One Time Commission %"
                  name="stripeOnetimeComission"
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item
                  label="Stripe Recurring Commission %"
                  name="stripeRecurringComission"
                >
                  <InputNumber />
                </Form.Item>
              </>
            </>
          )}
          <Form.Item label="Category" name="category">
            <Select
              options={categories.map((category) => ({
                label: category.name,
                value: category._id,
              }))}
            />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) => prev.category !== curr.category}
          >
            {({ getFieldValue }) => (
              <Form.Item label="Sub Category" name="subCategory">
                <Select
                  options={categories
                    .find(
                      (category) => category._id === getFieldValue("category")
                    )
                    ?.children.map((subCategory) => ({
                      label: subCategory.name,
                      value: subCategory._id,
                    }))}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item name="whitelableEnabled" valuePropName="checked">
            <Checkbox>White Label Enabled</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* Modal for pay MG to creator */}
      <Modal
        title="Pay MG"
        visible={selectedUserForMGPay}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        destroyOnClose
      >
        <Form
          name="basic"
          initialValues={{ rank: selectedUserForMGPay?.rank }}
          onFinish={onFinishMGPayModal}
        >
          <Divider className="divideMGPay">1. Verify the User</Divider>
          <Space size="small">
            <Avatar src={selectedUserForMGPay?.profilePicUrl} size={32} />
            <Typography style={{ fontWeight: 500, fontSize: 14 }}>
              {selectedUserForMGPay?.name}({selectedUserForMGPay?.onboarding})
            </Typography>
          </Space>
          <Divider className="divideMGPay">
            2. Pay on given account details & Upload Pay slip
          </Divider>
          <Row>
            <Col span={12}>
              <Typography style={{ fontWeight: 500, fontSize: 14 }}>
                Upload Pay Slip
              </Typography>
              <Upload
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                accept="image/*"
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="avatar"
                    id="imageID"
                    style={{
                      width: "100%",
                      maxHeight: "106px",
                      maxWidth: "106px",
                    }}
                  />
                ) : (
                  <div>
                    {imgLoading ? (
                      <LoadingOutlined style={{ zIndex: 1000 }} />
                    ) : (
                      <PlusOutlined />
                    )}
                    <div className="ant-upload-text">Upload</div>
                  </div>
                )}
              </Upload>
            </Col>
            {selectedUserForMGPay?.accountNumber ? (
              <Col span={12}>
                <Typography style={{ fontWeight: 500, fontSize: 14 }}>
                  Account Name:
                </Typography>
                <span style={{ paddingLeft: "20px" }}>
                  {selectedUserForMGPay?.accountName || "No data found"}
                </span>
                <Typography style={{ fontWeight: 500, fontSize: 14 }}>
                  Account Number:
                </Typography>
                <span style={{ paddingLeft: "20px" }}>
                  {selectedUserForMGPay?.accountNumber || "No data found"}
                </span>
                <Typography style={{ fontWeight: 500, fontSize: 14 }}>
                  Bank Name:
                </Typography>
                <span style={{ paddingLeft: "20px" }}>
                  {selectedUserForMGPay?.bankName || "No data found"}
                </span>
                <Typography style={{ fontWeight: 500, fontSize: 14 }}>
                  IFSC:
                </Typography>
                <span style={{ paddingLeft: "20px" }}>
                  {selectedUserForMGPay?.ifsc || "No data found"}
                </span>
              </Col>
            ) : (
              <span style={{ paddingLeft: "20px", color: "red" }}>
                Bank details not added !!
              </span>
            )}
          </Row>
          <Divider className="divideMGPay">
            3. Enter following details & Submit
          </Divider>
          <Form.Item
            label="Transer Amount:"
            name="transerAmount"
            rules={[
              { required: true, message: "Please input Transer Amount!" },
            ]}
          >
            <Input type="number" min="0.01" step="0.01" />
          </Form.Item>
          <Form.Item
            label="Manual TranserID:"
            name="manualTranserID"
            rules={[
              { required: true, message: "Please input Manual TranserID!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            // label="Yes, I verify the added details"
            name="checkBox"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error("Please check added details Onec")
                      ),
              },
            ]}
          >
            <Checkbox>Yes, I verify the added details</Checkbox>
          </Form.Item>
          <br />
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={buttonLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

AllUser.propTypes = {
  sensitiveAllowedActions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AllUser;
