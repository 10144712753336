/* eslint-disable camelcase */
import moment from "moment";

import request from "./request";

class QueryBuilder {
  constructor() {
    this.query = new URLSearchParams();
  }

  add(key, value) {
    if (value) {
      this.query.append(key, value);
    }
    return this;
  }

  build() {
    return this.query.toString();
  }
}

export const getEarningByMonthAndMango = (userId, currentDate) => {
  return request({
    url: `/admin/get-user-earnings/${userId}?currentMonth=${currentDate}`,
    method: "GET",
  });
};

export const getNoAccountUser = (page) => {
  return request({
    url: `/admin/get-no-account-user?page=${page}`,
    method: "GET",
  });
};

export const getNonTransferredPayments = (page, date, searchValue) => {
  return request({
    url: `/admin/get-non-transferred-payments?page=${page}&beforeDate=${date}&search=${searchValue}`,
    method: "GET",
  });
};

export const getAllUserList = (page, searchValue) => {
  const term = searchValue ? `&term=${searchValue}` : ``;
  return request({
    url: `/admin/get-all-user-list?page=${page}${term}`,
    method: "GET",
  });
};

export const getCreatorOrderList = (userId, page, date, radioValue) => {
  const orderType = radioValue !== "both" ? `&orderType=${radioValue}` : ``;
  return request({
    url: `admin/get-order-list/creator/${userId}?page=${page}&beforeDate=${date}${orderType}`,
    method: "GET",
  });
};

export const getCreatorMangoList = (userId, date) => {
  return request({
    url: `admin/get-mango-list/creator/${userId}?beforeDate=${date}`,
    method: "GET",
  });
};

export const getIncommingToPay = (date) => {
  return request({
    url: `/admin/get-incomming-to-pay?beforeDate=${date.toISOString()}`,
    method: "GET",
  });
};

export const getAllUser = (page, query, filterUserType) => {
  const searchQuery = new URLSearchParams();
  if (query) searchQuery.append("term", query);
  if (page) searchQuery.append("page", page);
  if (filterUserType !== "all") searchQuery.append("userType", filterUserType);

  return request({
    url: `/admin/get-all-user?${searchQuery.toString()}`,
    method: "GET",
  });
};

export const getPerDayEaring = (date, selectedRole) => {
  const selectedDate = date || new Date().toISOString();
  const startDay = moment(new Date(selectedDate)).startOf("day").toISOString();
  const endDay = moment(new Date(selectedDate)).endOf("day").toISOString();
  return request({
    url: `/admin/get-per-day-earing?date=${date}&userType=${selectedRole}&startDay=${startDay}&endDay=${endDay}`,
    method: "GET",
  });
};

export const userLogin = (data) => {
  return request({
    url: `/admin/login`,
    method: "post",
    data,
  });
};

export const getDateRangeEaring = (
  startDate,
  endDate,
  onlyEarning,
  page,
  searchBy,
  searchFieldText
) => {
  const searchQuery = searchBy
    ? `&searchBy=${searchBy}&term=${searchFieldText}`
    : "";
  return request({
    url: `/admin/get-date-range-earing?startDate=${startDate}&endDate=${endDate}&onlyEarning=${onlyEarning}&page=${page}${searchQuery}`,
    method: "GET",
  });
};

export const getDateRangeEarningV2 = (
  startDate,
  endDate,
  search,
  searchFields,
  orderStatus,
  onlyZapier,
  onlyMoengage,
  csv,
  page,
  onlyEarning,
  onlyWhitelabel
) => {
  const query = new URLSearchParams();

  if (page) {
    query.append("page", page);
  }
  if (startDate && endDate) {
    query.append("startDate", startDate);
    query.append("endDate", endDate);
  }
  if (search) query.append("search", search);
  if (onlyWhitelabel) query.append("onlyWhitelabel", true);

  if (onlyEarning) {
    query.append("onlyEarning", true);
  } else {
    if (orderStatus) query.append("orderStatus", orderStatus);
    if (csv) query.append("csv", csv);
    if (onlyZapier) query.append("onlyZapier", onlyZapier);
    if (onlyMoengage) query.append("onlyMoengage", onlyMoengage);

    if (
      searchFields &&
      Array.isArray(searchFields) &&
      searchFields.length > 0
    ) {
      searchFields.forEach((field) => {
        query.append("searchFields[]", field);
      });
    }
  }
  return request({
    url: `/admin/v2/get-date-range-earning?${query.toString()}`,
    method: "GET",
  });
};

export const getDateRangeUserDetails = (
  startDate,
  endDate,
  onlyCount,
  userType,
  page,
  searchBy,
  searchFieldText,
  onlyWhitelabel
) => {
  const query = new URLSearchParams();
  if (page) {
    query.append("page", page);
  }
  if (startDate && endDate) {
    query.append("startDate", startDate);
    query.append("endDate", endDate);
  }
  if (searchBy && searchFieldText) {
    query.append("searchBy", searchBy);
    query.append("term", searchFieldText);
  }
  if (userType) query.append("userType", userType);
  if (onlyCount) query.append("onlyCount", onlyCount);
  if (onlyWhitelabel) query.append("onlyWhitelabel", onlyWhitelabel);

  return request({
    url: `/admin/get-date-range-user-details?${query.toString()}`,
    method: "GET",
  });
};

export const getUserDetailsFromOrder = (startDate, endDate, userType) => {
  const userParams = userType ? `&userType=${userType}` : "";
  return request({
    url: `/admin/get-user-details-from-order?startDate=${startDate}&endDate=${endDate}${userParams}`,
    method: "GET",
  });
};

export const updateUserDetails = (data) => {
  return request({
    url: `/admin/update_user_details`,
    method: "post",
    data,
  });
};

export const getAllInfluencers = (term, page) => {
  const termString = term ? `&term=${term}` : "";
  return request({
    url: `/get-all-influencers?page=${page}${termString}`,
    method: "GET",
  });
};

export const getCreatorDetailsWithHiddenMangoes = (
  id,
  fetchSubscriberCount = false
) => {
  return request({
    url: `/get-creator-details/${id}?subscriber_count=${fetchSubscriberCount}&showhiddenmangoes=true`,
    method: "GET",
  });
};

export const sendPushNotifications = (data) => {
  return request({
    url: "/admin/v2/send-push-notification",
    method: "POST",
    data,
  });
};

export const uploadImage = (data) => {
  return request({
    url: `/admin/upload-image`,
    method: "POST",
    data,
  });
};

export const uploadFileStatic = (data) => {
  return request({
    url: `/admin/upload-file-static`,
    method: "POST",
    data,
  });
};

export const manualTransfer = (data) => {
  return request({
    url: `/admin/manual-transfer`,
    method: "POST",
    data,
  });
};

export const createCoupon = (data) => {
  return request({
    url: `/admin/create-coupon`,
    method: "POST",
    data,
  });
};

export const getCoupon = (data) => {
  return request({
    url: `/admin/get-coupons`,
    method: "POST",
    data,
  });
};

export const deactivateCoupon = (data) => {
  return request({
    url: "/admin/deactivate-coupon",
    method: "POST",
    data,
  });
};

export const getRequestedAccessCreators = (query) => {
  return request({
    url: `/admin/requested-access?search=${query}`,
    method: "GET",
  });
};

export const approveCreator = (userId) => {
  return request({
    url: `/admin/approve-creator?userId=${userId}`,
    method: "GET",
  });
};

export const editCoupon = (data) => {
  return request({
    url: `/admin/edit-coupon?coupon=${data.code}`,
    method: "POST",
    data,
  });
};

export const getCreatorsEarnings = (
  startDate,
  endDate,
  search,
  nature,
  type,
  csv,
  page
) => {
  const query = new URLSearchParams();
  if (page) {
    query.append("page", page);
  }
  if (search) query.append("search", search);
  if (startDate && endDate) {
    query.append("startDate", startDate);
    query.append("endDate", endDate);
  }
  if (csv) query.append("csv", true);
  if (type && type !== "all") query.append("creatorType", type);
  if (nature) {
    nature.forEach((elem) => {
      query.append("nature[]", elem);
    });
  }
  return request({
    url: `/admin/creator-earnings?${query.toString()}`,
    method: "GET",
  });
};

export const changeCreatorNature = (data) => {
  return request({
    url: `/admin/change-creator-nature`,
    method: "POST",
    data,
  });
};

export const getCohortSheet = () => {
  return request({
    url: `/admin/all-creator-earnings-with-month?csv=true`,
    method: "GET",
  });
};

export const getRestrictionStatus = () => {
  return request({
    url: `/admin/get-restriction-status`,
    method: "GET",
  });
};

export const getActiveSubscribers = (creator, mango) => {
  return request({
    url: `/admin/get-active-subscribers?creator=${creator}&mango=${mango}`,
    method: "GET",
  });
};

export const sendCertificateMails = (data, sendTest) => {
  return request({
    url: `/admin/send-certificate-mails?sendTest=${sendTest}`,
    method: "POST",
    data,
  });
};

export const getActivePaymentTerm = (creator) => {
  return request({
    url: `/admin/get-active-term?creator=${creator}`,
    method: "GET",
  });
};

export const createPaymentTerm = (data) => {
  return request({
    url: `/admin/create-payment-term`,
    method: "POST",
    data,
  });
};

export const editPaymentTerm = (id, data) => {
  return request({
    url: `/admin/edit-payment-term?id=${id}`,
    method: "POST",
    data,
  });
};

export const getUpcomingPayouts = (
  endDate,
  page,
  limit,
  search,
  paymentTermId,
  customLastDate
) => {
  return request({
    url: `/admin/get-upcoming-payouts?endDate=${endDate}&page=${page}&limit=${limit}${
      search !== "" ? `&search=${search}` : ""
    }${paymentTermId ? `&paymentTermId=${paymentTermId}` : ""}${
      customLastDate ? `&customLastDate=${customLastDate}` : ""
    }`,
    method: "GET",
  });
};

export const createPayout = (data) => {
  return request({
    url: `/admin/create-payout`,
    method: "POST",
    data,
  });
};

export const getPayoutHistory = (creator, startDate, endDate, page, limit) => {
  return request({
    url: `/admin/get-payout-history?creator=${creator}&startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`,
    method: "GET",
  });
};

export const getUpcomingPayoutOrders = (
  paymentTerm,
  endDate,
  page,
  limit,
  search
) => {
  return request({
    url: `/admin/get-upcoming-payout-orders?paymentTerm=${paymentTerm}&endDate=${endDate}&page=${page}&limit=${limit}${
      search !== "" ? `&search=${search}` : ""
    }`,
    method: "GET",
  });
};

export const getCouponsV2 = (
  startDate,
  endDate,
  search,
  creatorId,
  showExpired,
  showMoengage,
  page,
  limit
) => {
  const query = new URLSearchParams();
  if (page && limit) {
    query.append("page", page);
    query.append("limit", limit);
  }
  if (startDate && endDate) {
    query.append("startDate", startDate);
    query.append("endDate", endDate);
  }
  if (search) query.append("search", search);
  if (creatorId) query.append("creatorId", creatorId);
  if (showExpired) query.append("showExpired", true);
  if (showMoengage) query.append("showMoengage", true);
  return request({
    method: "GET",
    url: `/admin/v2/get-coupons?${query.toString()}`,
  });
};

export const getAllAdminUsers = (search) => {
  const query = new URLSearchParams();
  if (search) query.append("search", search);
  return request({
    method: "GET",
    url: `/admin/all-admin-users?${query.toString()}`,
  });
};

export const updateAdminUser = (user, data) => {
  return request({
    method: "POST",
    url: `/admin/update-admin-user?user=${user}`,
    data,
  });
};

export const signup = (data) => {
  return request({
    method: "POST",
    url: `/admin/signup`,
    data,
  });
};

export const sendDashboardMails = (data) => {
  return request({
    method: "POST",
    url: `/admin/send-dashboard-mails`,
    data,
  });
};

export const getRecipientsCount = (data) => {
  return request({
    method: "POST",
    url: `/admin/get-recipients-count`,
    data,
  });
};

export const getDashboardMails = (startDate, endDate, search, page, limit) => {
  const query = new URLSearchParams();

  if (page && limit) {
    query.append("page", page);
    query.append("limit", limit);
  }
  if (startDate && endDate) {
    query.append("startDate", startDate);
    query.append("endDate", endDate);
  }
  if (search) query.append("search", search);
  return request({
    method: "GET",
    url: `/admin/get-dashboard-mails?${query.toString()}`,
  });
};

export const getMailsByCampaign = (
  campaignId,
  deliveryStatus,
  opened,
  search,
  page,
  limit
) => {
  return request({
    method: "GET",
    url: `/admin/get-mails-by-campaign?campaignId=${campaignId}${
      deliveryStatus ? `&deliveryStatus=${deliveryStatus}` : ""
    }${opened ? `&opened=${opened}` : ""}${
      search ? `&search=${search}` : ""
    }&page=${page}&limit=${limit}`,
  });
};

export const getCampaigns = (search, page, limit) => {
  return request({
    method: "GET",
    url: `/admin/get-mail-campaigns?search=${search}&page=${page}&limit=${limit}`,
  });
};

export const getWithdrawalRequests = (
  startDate,
  endDate,
  approvalStatus,
  search,
  transferMode,
  automaticEligible,
  payoutStatus,
  page,
  limit,
  csv
) => {
  const query = new URLSearchParams();
  if (page && limit) {
    query.append("page", page);
    query.append("limit", limit);
  }
  if (startDate && endDate) {
    query.append("startDate", startDate);
    query.append("endDate", endDate);
  }
  if (search) query.append("search", search);
  if (approvalStatus) query.append("approvalStatur", approvalStatus);
  if (csv) query.append("csv", true);
  if (transferMode) query.append("transferMode", transferMode);
  if (automaticEligible) query.append("automaticEligible", automaticEligible);
  if (payoutStatus) query.append("payoutStatus", payoutStatus);
  return request({
    method: "GET",
    url: `/admin/get-withdrawal-requests?${query.toString()}`,
  });
};

export const getWalletWithdrawalRequests = (
  startDate,
  endDate,
  approvalStatus,
  search,
  transferMode,
  automaticEligible,
  payoutStatus,
  whitelabelPlanType,
  userTier,
  creatorCountryType,
  page,
  limit,
  csv
) => {
  const query = new URLSearchParams();
  if (page && limit) {
    query.append("page", page);
    query.append("limit", limit);
  }
  if (startDate && endDate) {
    query.append("startDate", startDate);
    query.append("endDate", endDate);
  }
  if (approvalStatus) query.append("approvalStatus", approvalStatus);
  if (search) query.append("search", search);
  if (csv) query.append("csv", true);
  if (transferMode) query.append("transferMode", transferMode);
  if (automaticEligible) query.append("automaticEligible", automaticEligible);
  if (payoutStatus) query.append("payoutStatus", payoutStatus);
  if (whitelabelPlanType)
    query.append("whitelabelPlanType", whitelabelPlanType);
  if (userTier) query.append("userTier", userTier);
  if (creatorCountryType)
    query.append("creatorCountryType", creatorCountryType);
  return request({
    method: "GET",
    url: `/admin/get-wallet-withdrawal-requests?${query.toString()}`,
  });
};

export const handleWithdrawalRequest = (data) => {
  return request({
    method: "POST",
    url: `/admin/handle-withdrawal-request`,
    data,
  });
};

export const handleWalletWithdrawalRequest = (data) => {
  return request({
    method: "POST",
    url: `/admin/handle-wallet-withdrawal-request`,
    data,
  });
};

export const getOrdersOfWithdrawal = (
  withdrawalId,
  search,
  statusFilter,
  page,
  limit
) => {
  return request({
    method: "GET",
    url: `/admin/get-orders-of-withdrawal?withdrawalId=${withdrawalId}${
      search ? `&search=${search}` : ""
    }&page=${page}&limit=${limit}${
      statusFilter ? `&statusFilter=${statusFilter}` : ""
    }`,
  });
};

export const createRefund = (data) => {
  return request({
    method: "POST",
    url: `/admin/create-refund`,
    data,
  });
};

export const downloadWithdrawalBreakdown = (withdrawalId, creatorId) => {
  return request({
    method: "GET",
    url: `/admin/withdrawal-breakdown?withdrawalId=${withdrawalId}&creator=${creatorId}`,
  });
};

export const getUpcomingMangos = (startDate, endDate, search, page, limit) => {
  return request({
    method: "GET",
    url: `/admin/upcoming-mangos?startDate=${startDate}&endDate=${endDate}${
      search ? `&search=${search}` : ""
    }&page=${page}&limit=${limit}`,
  });
};

export const getCreatorFunnelUTMS = (search, page, limit) => {
  return request({
    method: "GET",
    url: `/admin/creator-utm-campaigns?search=${search}&page=${page}&limit=${limit}`,
  });
};

export const getFunnelCounts = (utm_campaign, startDate, endDate) => {
  return request({
    method: "GET",
    url: `/admin/creator-funnel-counts?utm_campaign=${utm_campaign}&startDate=${startDate}&endDate=${endDate}`,
  });
};

export const getFunnelStageCreators = (
  utm_campaign,
  startDate,
  endDate,
  funnelStage,
  page,
  limit,
  csv
) => {
  return request({
    method: "GET",
    url: `/admin/funnel-stage-creators?utm_campaign=${utm_campaign}&startDate=${startDate}&endDate=${endDate}&funnelStage=${funnelStage}&page=${page}&limit=${limit}${
      csv ? "&csv=true" : ""
    }`,
  });
};

export const getCategories = () => {
  return request({
    method: "GET",
    url: `/categories`,
  });
};

export const getPendingBytescareRequests = () => {
  return request({
    method: "GET",
    url: `/admin/bytescare-pending-requests`,
  });
};

export const approveRequestToBytescare = (data) => {
  return request({
    method: "POST",
    url: `/admin/approve-bytescare-request`,
    data,
  });
};

export const getManualRefundRequests = (page, limit) => {
  return request({
    method: "GET",
    url: `/admin/get-refund-requests?page=${page}&limit=${limit}`,
  });
};

export const migrateSubs = (creatorId, mangoId, coupon, subs) => {
  return request({
    method: "POST",
    url: `/admin/migrate-users?creatorId=${creatorId}&mangoId=${mangoId}&coupon=${coupon}`,
    data: {
      subs,
    },
  });
};

export const checkAutomaticWithdrawalStatus = (withdrawalId) => {
  return request({
    method: "GET",
    url: `/admin/withdrawal-status?withdrawalId=${withdrawalId}`,
  });
};

export const getWhatsappUsers = (
  status,
  subscriptionStatus,
  search,
  expiryDateStart,
  expiryDateEnd,
  autopayStatus,
  page,
  limit,
  csv
) => {
  const query = new URLSearchParams();
  if (page && limit) {
    query.append("page", page);
    query.append("limit", limit);
  }
  if (search) query.append("search", search);
  if (status) query.append("status", status);
  if (subscriptionStatus)
    query.append("subscriptionStatus", subscriptionStatus);
  if (csv) query.append("csv", true);
  if (expiryDateStart) query.append("expiryDateStart", expiryDateStart);
  if (expiryDateEnd) query.append("expiryDateEnd", expiryDateEnd);
  if (autopayStatus) query.append("autopayStatus", autopayStatus);

  return request({
    method: "GET",
    url: `/admin/whatsapp-users?${query.toString()}`,
  });
};

export const updateWhatsappConfig = (data) => {
  return request({
    method: "POST",
    url: `/admin/whatsapp-config`,
    data,
  });
};

export const updateWhatsappTierExpiry = (data) => {
  return request({
    method: "POST",
    url: `/admin/whatsapp-expiry`,
    data,
  });
};

export const getCommissionTaxReport = (
  startDate,
  endDate,
  search,
  creatorCountryType,
  page,
  limit,
  csv
) => {
  const queryBuild = new QueryBuilder();
  queryBuild.add("startDate", startDate);
  queryBuild.add("endDate", endDate);
  queryBuild.add("search", search);
  queryBuild.add("creatorCountryType", creatorCountryType);
  queryBuild.add("page", page);
  queryBuild.add("limit", limit);
  queryBuild.add("csv", csv);
  return request({
    method: "GET",
    url: `/admin/get-commission-convenience-tax-report?${queryBuild.build()}`,
  });
};

export const getWhatsappSubscriptionTaxReport = (
  startDate,
  endDate,
  search,
  creatorCountryType,
  page,
  limit,
  csv
) => {
  const queryBuild = new QueryBuilder();
  queryBuild.add("startDate", startDate);
  queryBuild.add("endDate", endDate);
  queryBuild.add("search", search);
  queryBuild.add("creatorCountryType", creatorCountryType);
  queryBuild.add("page", page);
  queryBuild.add("limit", limit);
  queryBuild.add("csv", csv);
  return request({
    method: "GET",
    url: `/admin/get-whatsapp-subs-tax-report?${queryBuild.build()}`,
  });
};

export const getMangoCreditsTaxReport = (
  startDate,
  endDate,
  search,
  creatorCountryType,
  page,
  limit,
  csv
) => {
  const queryBuild = new QueryBuilder();
  queryBuild.add("startDate", startDate);
  queryBuild.add("endDate", endDate);
  queryBuild.add("search", search);
  queryBuild.add("creatorCountryType", creatorCountryType);
  queryBuild.add("page", page);
  queryBuild.add("limit", limit);
  queryBuild.add("csv", csv);
  return request({
    method: "GET",
    url: `/admin/get-mango-credits-tax-report?${queryBuild.build()}`,
  });
};

export const getAffiliatesEarnings = (
  startDate,
  endDate,
  search,
  creator,
  page,
  limit,
  csv
) => {
  const query = new URLSearchParams();
  if (page && limit) {
    query.append("page", page);
    query.append("limit", limit);
  }
  if (startDate && endDate) {
    query.append("startDate", startDate);
    query.append("endDate", endDate);
  }
  if (search) query.append("search", search);
  if (csv) query.append("csv", true);
  if (creator) query.append("creator", creator);
  return request({
    method: "GET",
    url: `/admin/affiliate-earnings?${query.toString()}`,
  });
};

export const getOrdersOfAffiliate = (
  affiliateId,
  startDate,
  endDate,
  search,
  page,
  limit,
  csv
) => {
  return request({
    method: "GET",
    url: `/admin/get-orders-of-affiliate?affiliate=${affiliateId}&startDate=${startDate}&endDate=${endDate}${
      page && limit ? `&page=${page}&limit=${limit}` : ""
    }${search ? `&search=${search}` : ""}${csv ? "&csv=true" : ""}`,
  });
};

export const getWithdrawalOtp = (withdrawalId) => {
  return request({
    method: "GET",
    url: `/admin/get-withdrawal-otp?withdrawalId=${withdrawalId}`,
  });
};

export const getWalletWithdrawalOtp = (withdrawalId) => {
  return request({
    method: "GET",
    url: `/admin/get-wallet-withdrawal-otp?withdrawalId=${withdrawalId}`,
  });
};

export const getBulkWithdrawalOtp = (data) => {
  return request({
    method: "POST",
    url: `/admin/get-bulk-withdrawal-otp`,
    data,
  });
};

export const getBulkWalletWithdrawalOtp = (data) => {
  return request({
    method: "POST",
    url: `/admin/get-bulk-wallet-withdrawal-otp`,
    data,
  });
};

export const handleBulkWithdrawal = (data) => {
  return request({
    method: "POST",
    url: `/admin/handle-bulk-withdrawal`,
    data,
  });
};

export const handleBulkWalletWithdrawal = (data) => {
  return request({
    method: "POST",
    url: `/admin/handle-bulk-wallet-withdrawal`,
    data,
  });
};

export const getTagmangoCreatorsData = (
  search,
  startDate,
  endDate,
  earningGt,
  earningLt,
  mangoCountGt,
  mangoCountLt,
  page,
  limit,
  csv
) => {
  const query = new URLSearchParams();
  if (page && limit) {
    query.append("page", page);
    query.append("limit", limit);
  }
  if (search) query.append("search", search);
  if (csv) query.append("csv", true);
  if (startDate && endDate) {
    query.append("startDate", startDate);
    query.append("endDate", endDate);
  }
  if (earningGt) query.append("earningGt", earningGt);
  if (earningLt) query.append("earningLt", earningLt);
  if (mangoCountGt) query.append("mangoCountGt", mangoCountGt);
  if (mangoCountLt) query.append("mangoCountLt", mangoCountLt);
  return request({
    method: "GET",
    url: `/admin/tagmango-creators-list?${query.toString()}`,
  });
};

export const getWhitelabelCreatorsData = (
  search,
  whitelabelPlanType,
  tier,
  domainActivationStatus,
  startDate,
  endDate,
  earningGt,
  earningLt,
  mangoCountGt,
  mangoCountLt,
  tierExpiryDateGt,
  tierExpiryDateLt,
  tierExpired,
  platformSuspensionStatus,
  tierAutopayStatus,
  page,
  limit,
  csv
) => {
  const query = new URLSearchParams();
  if (page && limit) {
    query.append("page", page);
    query.append("limit", limit);
  }
  if (search) query.append("search", search);
  if (csv) query.append("csv", true);
  if (whitelabelPlanType)
    query.append("whitelabelPlanType", whitelabelPlanType);
  if (tier) query.append("tier", tier);
  if (startDate && endDate) {
    query.append("startDate", startDate);
    query.append("endDate", endDate);
  }
  if (earningGt) query.append("earningGt", earningGt);
  if (earningLt) query.append("earningLt", earningLt);
  if (mangoCountGt) query.append("mangoCountGt", mangoCountGt);
  if (mangoCountLt) query.append("mangoCountLt", mangoCountLt);
  if (domainActivationStatus)
    query.append("domainActivationStatus", domainActivationStatus);
  if (tierExpiryDateGt)
    query.append("subscriptionExpiryDateStart", tierExpiryDateGt);
  if (tierExpiryDateLt)
    query.append("subscriptionExpiryDateEnd", tierExpiryDateLt);
  if (tierExpired) query.append("tierExpired", tierExpired);
  if (platformSuspensionStatus)
    query.append("platformSuspensionStatus", platformSuspensionStatus);
  if (tierAutopayStatus) query.append("tierAutopayStatus", tierAutopayStatus);
  return request({
    method: "GET",
    url: `/admin/whitelabel-creators-list?${query.toString()}`,
  });
};

export const getCreatorDetails = (creatorId) => {
  return request({
    method: "GET",
    url: `/admin/get-creator-details?userId=${creatorId}`,
  });
};

export const upgradePlan = (data) => {
  return request({
    method: "POST",
    url: `/admin/upgrade-wl-plan`,
    data,
  });
};

export const upgradeTier = (data) => {
  return request({
    method: "POST",
    url: `/admin/upgrade-wl-tier`,
    data,
  });
};

export const disableWhatsappConfig = (data) => {
  return request({
    method: "POST",
    url: `/admin/disable-whatsapp-config`,
    data,
  });
};

export const enableWhatsappConfig = (data) => {
  return request({
    method: "POST",
    url: `/admin/enable-whatsapp-config`,
    data,
  });
};

export const suspendPlatform = (data) => {
  return request({
    method: "POST",
    url: `/admin/suspend-platform`,
    data,
  });
};

export const getLedgerOfWallet = (
  user,
  startDate,
  endDate,
  transactionType,
  page,
  limit,
  csv
) => {
  return request({
    method: "GET",
    url: `/admin/get-ledger-of-wallet?user=${user}&startDate=${startDate}&endDate=${endDate}&transactionType=${transactionType}&page=${page}&limit=${limit}${
      csv ? "&csv=true" : ""
    }`,
  });
};

export const getOrdersOfWallet = (
  user,
  startDate,
  endDate,
  page,
  limit,
  csv
) => {
  return request({
    method: "GET",
    url: `/admin/get-orders-for-wallet?user=${user}&startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}${
      csv ? "&csv=true" : ""
    }`,
  });
};

export const getTotalWithdrawnOldToDate = (user) => {
  return request({
    method: "GET",
    url: `/admin/total-withdrawn-to-date?user=${user}`,
  });
};

export const getHomeWidgets = (startDate, endDate, onlyWhitelabel) => {
  return request({
    method: "GET",
    url: `/admin/home-widgets?startDate=${startDate}&endDate=${endDate}${
      onlyWhitelabel ? "&onlyWhitelabel=true" : ""
    }`,
  });
};

export const updateWLExpiry = (data) => {
  return request({
    method: "POST",
    url: `/admin/update-wl-expiry`,
    data,
  });
};

export const getRefernEarnRequests = (
  accessStatus,
  search,
  page,
  limit,
  csv
) => {
  const query = new URLSearchParams();
  if (page && limit) {
    query.append("page", page);
    query.append("limit", limit);
  }
  if (search) query.append("search", search);
  if (csv) query.append("csv", true);
  if (accessStatus) query.append("accessStatus", accessStatus);
  return request({
    method: "GET",
    url: `/admin/refern-earn-requests?${query.toString()}`,
  });
};

export const updateRefernEarnRequest = (data) => {
  return request({
    method: "POST",
    url: `/admin/update-refern-earn-request`,
    data,
  });
};

export const getRefernEarnEarnings = (
  startDate,
  endDate,
  search,
  page,
  limit,
  csv
) => {
  const query = new URLSearchParams();
  if (page && limit) {
    query.append("page", page);
    query.append("limit", limit);
  }
  if (search) query.append("search", search);
  if (csv) query.append("csv", true);
  if (startDate && endDate) {
    query.append("startDate", startDate);
    query.append("endDate", endDate);
  }
  return request({
    method: "GET",
    url: `/admin/refern-earn-earnings?${query.toString()}`,
  });
};

export const getRefernEarnProductSales = (user, startDate, endDate) => {
  return request({
    method: "GET",
    url: `/admin/refern-product-sales?user=${user}${
      startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : ""
    }`,
  });
};

export const getRefernEarnSalesOfProduct = (
  product,
  startDate,
  endDate,
  search,
  page,
  limit,
  csv
) => {
  return request({
    method: "GET",
    url: `/admin/refern-product-sales-data?product=${product}${
      startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : ""
    }${search ? `&search=${search}` : ""}&page=${page}&limit=${limit}${
      csv ? "&csv=true" : ""
    }`,
  });
};

export const createRefernEarnPayout = (data) => {
  return request({
    method: "POST",
    url: `/admin/create-refern-earn-payout`,
    data,
  });
};

export const getRefernEarnPayouts = (user) => {
  return request({
    method: "GET",
    url: `/admin/refern-earn-payouts?user=${user}`,
  });
};

export const createRefernEarnProduct = (data) => {
  return request({
    method: "POST",
    url: `/admin/create-refern-earn-product`,
    data,
  });
};

export const editRefernEarnProduct = (product, data) => {
  return request({
    method: "POST",
    url: `/admin/update-refern-earn-product?product=${product}`,
    data,
  });
};

export const tagUserWithRefernEarnParent = (data) => {
  return request({
    method: "PATCH",
    url: `/admin/refern-earn/signup-tag`,
    data,
  });
};

export const cancelTierSubscription = (data) => {
  return request({
    method: "POST",
    url: `/admin/cancel-tier-subscription`,
    data,
  });
};

export const cancelWhatsappSubscription = (data) => {
  return request({
    method: "POST",
    url: `/admin/cancel-whatsapp-subscription`,
    data,
  });
};

export const getWhatsappOrdersOfUser = (userId, page, limit) => {
  return request({
    method: "GET",
    url: `/admin/whatsapp-orders-of-user?userId=${userId}&page=${page}&limit=${limit}`,
  });
};

export const getTierOrders = (
  startDate,
  endDate,
  search,
  tier,
  page,
  limit,
  csv
) => {
  const query = new URLSearchParams();
  if (page && limit) {
    query.append("page", page);
    query.append("limit", limit);
  }
  if (search) query.append("search", search);
  if (tier && tier?.length) {
    tier.forEach((each) => {
      query.append("tier[]", each);
    });
  }
  if (csv) query.append("csv", true);
  if (startDate && endDate) {
    query.append("startDate", startDate);
    query.append("endDate", endDate);
  }
  return request({
    method: "GET",
    url: `/admin/tier-orders?${query.toString()}`,
  });
};

export const getRefernEarnSignups = (user, page, limit, csv) => {
  return request({
    method: "GET",
    url: `/admin/refern-earn-signups?user=${user}${
      page && limit ? `&page=${page}&limit=${limit}` : ""
    }${csv ? "&csv=true" : ""}`,
  });
};

export const getOnboardingStats = (startDate, endDate, search) => {
  const query = new URLSearchParams();
  if (startDate && endDate) {
    query.append("startDate", startDate);
    query.append("endDate", endDate);
  }
  if (search) query.append("search", search);
  return request({
    method: "GET",
    url: `/admin/get-onboarding-stats?${query}`,
  });
};

export const getOnboardingData = (
  search,
  startDate,
  endDate,
  page,
  limit,
  csv
) => {
  const query = new URLSearchParams();
  if (page && limit) {
    query.append("page", page);
    query.append("limit", limit);
  }
  if (search) query.append("search", search);
  if (startDate && endDate) {
    query.append("startDate", startDate);
    query.append("endDate", endDate);
  }
  if (csv) query.append("csv", true);
  return request({
    method: "GET",
    url: `/admin/get-onboarding-data?${query.toString()}`,
  });
};

// Banner API starts //

export const getBanners = () => {
  return request({
    method: "GET",
    url: `/admin/banners`,
  });
};

export const createBanners = (data) => {
  return request({
    method: "POST",
    url: `/admin/banners`,
    data,
  });
};

export const updateBanners = (data, id) => {
  return request({
    method: "PATCH",
    url: `/admin/banners/${id}`,
    data,
  });
};

export const getCreatorPayables = (
  startDate,
  endDate,
  search,
  whitelabelPlanType,
  userTier,
  creatorCountryType,
  page,
  limit,
  csv
) => {
  const query = new QueryBuilder()
    .add("startDate", startDate)
    .add("endDate", endDate)
    .add("search", search)
    .add("whitelabelPlanType", whitelabelPlanType)
    .add("userTier", userTier)
    .add("creatorCountryType", creatorCountryType)
    .add("page", page)
    .add("limit", limit)
    .add("csv", csv);

  return request({
    method: "GET",
    url: `/admin/creator-payables?${query.build()}`,
  });
};

// Banner API ends //
