import React from "react";

import PropTypes from "prop-types";

// import UserDetails from "../UserDetails/index";
import Affiliates from "../Affiliates";
import AllUser from "../AllUser/index";
import BytescareRequests from "../Bytescare";
import CertificatesSection from "../CertificatesSection";
import CouponSection from "../CouponSection";
import CreatorAccess from "../CreatorAccess";
import CreatorEarnings from "../CreatorEarnings";
import CreatorsFunnel from "../CreatorFunnel";
import DashboardAccess from "../DasboardAccess";
import DashboardBanner from "../DashboardBanner";
import EmailSection from "../EmailSection";
import EnterpriseCreators from "../EnterpriseCreators";
import FreedomCreators from "../FreedomCreators";
import Home from "../HomePage/index";
import Migration from "../Migration";
import NoAccountUser from "../NoAccountUser/index";
import NonTransferredPayment from "../NonTransferredPayment/index";
import OnboardingDetails from "../OnboardingDetails";
import OrderDetailsV2 from "../OrderDetailsV2/index";
import PaymentTerms from "../PaymentTerms";
import PayoutsSchedule from "../PayoutsSchedule";
import PushNotifications from "../PushNotifications";
import RefernEarnEarnings from "../RefernEarnEarnings";
import RefernEarnRequests from "../RefernEarnRequests";
import Refunds from "../Refunds";
import TagmangoCreators from "../TagmangoCreators";
import TaxReports from "../TaxReports";
import TierOrders from "../TierOrders";
import UpcomingMangos from "../UpcomingMangos";
import UserDetailsList from "../UserDetailsList/index";
import WhatsappManagement from "../WhatsappManagement";
import WhitelabelHome from "../WhitelabelHome";
import WithdrawalsSection from "../WithdrawalsSection";
import CreatorPayables from "../CreatorPayables";
import IndianWalletWithdrawals from "../WalletWithdrawals/indian";
import InternationalWalletWithdrawals from "../WalletWithdrawals/international";

const Dashboard = ({
  sliderValue,
  setSliderValue,
  isRestricted,
  allowedFunctions,
  sensitiveAllowedActions,
}) => {
  // const dateFormat = "DD MMM YYYY";
  const dashboardComponents = {
    home: <Home setSliderValue={setSliderValue} />,
    whitelabelHome: <WhitelabelHome setSliderValue={setSliderValue} />,
    allUser: <AllUser sensitiveAllowedActions={sensitiveAllowedActions} />,
    noAccountUser: <NoAccountUser />,
    nonTransferred: <NonTransferredPayment isRestricted={isRestricted} />,
    orderDetails: <OrderDetailsV2 />,
    // refundRequests: <RefundRequests />,
    refunds: <Refunds />,
    creatorEarnings: <CreatorEarnings isRestricted={isRestricted} />,
    userDetailsList: <UserDetailsList />,
    pushNotifications: <PushNotifications />,
    couponSection: <CouponSection />,
    creatorAccess: <CreatorAccess />,
    certificatesSection: <CertificatesSection />,
    paymentTerms: <PaymentTerms />,
    payoutsSchedule: <PayoutsSchedule />,
    dashboardAccess: <DashboardAccess />,
    emailSection: <EmailSection />,
    widthdrawalsSection: <WithdrawalsSection />,
    upcomingMangos: <UpcomingMangos />,
    creatorsFunnel: <CreatorsFunnel />,
    bytescareRequests: <BytescareRequests />,
    migration: <Migration />,
    whatsappManagement: <WhatsappManagement />,
    taxReports: <TaxReports />,
    affiliateEarnings: <Affiliates />,
    tagmangoCreators: <TagmangoCreators />,
    freedomCreators: <FreedomCreators />,
    enterpriseCreators: <EnterpriseCreators />,
    indianWalletWithdrawals: <IndianWalletWithdrawals />,
    internationalWalletWithdrawals: <InternationalWalletWithdrawals />,
    refernEarnRequests: <RefernEarnRequests />,
    refernEarnEarnings: <RefernEarnEarnings />,
    tierOrders: <TierOrders />,
    onboardingDetails: <OnboardingDetails />,
    dashboardBanner: <DashboardBanner />,
    creatorPayables: <CreatorPayables />,
  };
  const renderPage = () =>
    isRestricted
      ? allowedFunctions.includes(sliderValue) &&
        dashboardComponents[sliderValue]
      : dashboardComponents[sliderValue];
  return (
    <div className="siteDashboard">
      {renderPage()}
      {/* {sliderValue === 'allUser' && <AllUser /> } */}
      {/* <Row justify="end" align="middle" gutter={[15, 15]}>
          <Col>
            <Form layout="horizontal">
              <Form.Item label="Data as on" style={{ marginBottom: 0 }}>
                <DatePicker.RangePicker
                  style={{ width: 250 }}
                  bordered={false}
                  defaultValue={[
                    moment("01 Jan '20", dateFormat),
                    moment("07 Jan '20", dateFormat),
                  ]}
                  ranges={{
                    Today: [moment(), moment()],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                  }}
                  format={dateFormat}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row> */}
      {/* <DashboardWidgets /> */}
      {/* <PaymentTable /> */}
    </div>
  );
};

Dashboard.propTypes = {
  sliderValue: PropTypes.string.isRequired,
  setSliderValue: PropTypes.func,
  isRestricted: PropTypes.bool.isRequired,
  allowedFunctions: PropTypes.arrayOf(PropTypes.string).isRequired,
  sensitiveAllowedActions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Dashboard;
