import React, { useEffect, useState } from "react";
import {
  Row,
  Input,
  Col,
  Select,
  DatePicker,
  Space,
  Button,
  Avatar,
  Typography,
  Table,
  message,
} from "antd";
import moment from "moment";
import fileDownload from "js-file-download";
import { getCreatorPayables } from "../api";

const { Option } = Select;

const CreatorPayables = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  // Hard code the start date to 9th March 2024 when the
  // ledger system started, why hard code? because i expect a future
  // requirement where we would want this to be variable.
  // why this date? because we migrated the order dates and not the actual
  // creation date.
  const startDate = moment(new Date("2022-03-01T16:32:32.956+00:00"));
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [creatorCountryType, setCreatorCountryType] = useState(null);
  const [whitelabelPlanType, setWhitelabelPlanType] = useState(null);
  const [userTier, setUserTier] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);

  const fetchCreatorPayables = async () => {
    setLoading(true);
    try {
      const resp = await getCreatorPayables(
        startDate.toISOString(),
        endDate.toISOString(),
        search,
        whitelabelPlanType,
        userTier,
        creatorCountryType,
        page,
        limit
      );
      if (resp?.code === 0) {
        setData(resp?.result?.data);
        setTotal(resp?.result?.total);
      } else {
        message.error(resp?.message);
      }
    } catch (error) {
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleCreatorPayablesCSVDownload = async () => {
    setCsvLoading(true);
    try {
      const resp = await getCreatorPayables(
        startDate.toISOString(),
        endDate.toISOString(),
        search,
        whitelabelPlanType,
        userTier,
        creatorCountryType,
        page,
        limit,
        true
      );
      fileDownload(resp, "creator-payables.csv");
    } catch (error) {
      message.error("Something went wrong");
    } finally {
      setCsvLoading(false);
    }
  };

  const columns = [
    {
      title: "Creator",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Space size="small" direction="vertical">
          <Space size="small">
            <Avatar src={record?.profilePicUrl} size={32} />
            <Typography
              style={{ fontWeight: 500, fontSize: 14, color: "#1890ff" }}
            >
              {record?.name}
            </Typography>
          </Space>
          <Typography
            style={{
              fontWeight: 300,
              fontSize: 14,
            }}
          >
            {record?.email}
          </Typography>
          <Typography
            style={{
              fontWeight: 300,
              fontSize: 14,
            }}
          >
            {record?.phone}
          </Typography>
          <Typography.Link>{record?.host}</Typography.Link>
          <Typography>
            <strong>
              ({record?.whitelabelPlanType || "N/A"}, {record?.userTier})
            </strong>
          </Typography>
        </Space>
      ),
    },
    {
      title: "Total Payables",
      dataIndex: "totalPayables",
      key: "totalPayables",
    },
    {
      title: "Total Withdrawn",
      dataIndex: "totalWithdrawn",
      key: "totalWithdrawn",
    },
  ];

  useEffect(() => {
    fetchCreatorPayables();
  }, [
    page,
    limit,
    endDate,
    search,
    whitelabelPlanType,
    userTier,
    creatorCountryType,
  ]);

  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: "10px",
        }}
      >
        <Col span={8}>
          <Input
            style={{ width: "100%" }}
            placeholder="Search Creator name, email, phone or host"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Col>
        <Col span={16}>
          <Space>
            <DatePicker
              value={endDate}
              onChange={(date) => setEndDate(moment(date).endOf("day"))}
              allowClear={false}
            />
            <Select
              allowClear
              placeholder="Select Plan"
              value={whitelabelPlanType}
              onSelect={(value) => setWhitelabelPlanType(value)}
              onClear={() => setWhitelabelPlanType(null)}
            >
              <Option value="freedom-plan">Freedom plan</Option>
              <Option value="enterprise-plan">Enterprise plan</Option>
            </Select>
            <Select
              allowClear
              placeholder="Select Tier"
              value={userTier}
              onSelect={(value) => setUserTier(value)}
              onClear={() => setUserTier(null)}
            >
              <Option value="tier1">Tier 1</Option>
              <Option value="tier2">Tier 2</Option>
              <Option value="tier3">Tier 3</Option>
              <Option value="tier4">Tier 4</Option>
            </Select>
            <Select
              allowClear
              placeholder="Select Country Type"
              value={creatorCountryType}
              onSelect={(value) => setCreatorCountryType(value)}
              onClear={() => setCreatorCountryType(null)}
            >
              <Option value="india">India</Option>
              <Option value="international">International</Option>
            </Select>
            <Button
              type="primary"
              onClick={() => {
                handleCreatorPayablesCSVDownload();
              }}
              loading={csvLoading}
            >
              Download CSV
            </Button>
            <Button
              onClick={() => {
                fetchCreatorPayables();
              }}
            >
              Refresh
            </Button>
          </Space>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          pageSize: limit,
          total,
          onChange: setPage,
          current: page,
          onShowSizeChange: (current, size) => setLimit(size),
        }}
        loading={loading}
      />
    </>
  );
};

export default CreatorPayables;
